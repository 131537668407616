/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 3, 2023 */



@font-face {
  font-family: 'safirobold';
  src: url('safiro-bold-webfont.eot');
  src: url('safiro-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('safiro-bold-webfont.woff2') format('woff2'),
    url('safiro-bold-webfont.woff') format('woff'),
    url('safiro-bold-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'safiromedium';
  src: url('safiro-medium-webfont.eot');
  src: url('safiro-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('safiro-medium-webfont.woff2') format('woff2'),
    url('safiro-medium-webfont.woff') format('woff'),
    url('safiro-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'safiroregular';
  src: url('safiro-regular-webfont.eot');
  src: url('safiro-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('safiro-regular-webfont.woff2') format('woff2'),
    url('safiro-regular-webfont.woff') format('woff'),
    url('safiro-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}